import { computed } from 'vue';
import { useStudentsStore } from '/@/app/store/students';
import { useAccountStore } from '/@/app/store/account';
import { get } from 'lodash';
import { useRoute } from 'vue-router';
import { unlockQuestionnaire } from '/@/app/services/surveys';

const tabs = [
  { name: 'questionnaires', title: 'שאלונים' },
  { name: 'progress', title: 'התקדמות' },
];

export function useSurvey(groupId: string, surveyId: string) {
  const studentsStore = useStudentsStore();
  const accountStore = useAccountStore();
  const route = useRoute();
  const isSurvey = computed(() => route.name?.toString() === 'survey-progress');

  const loadSurveyData = () => {
    studentsStore.fetchSurveyEnrollments({
      group: groupId,
      survey: surveyId,
    });
  };

  const enrollmentsSummary = computed(() => {
    return get(studentsStore.surveyEnrollments[0], 'summary', []);
  });

  const surveyListItems = computed(() => [
    {
      title: 'שאלונים',
      items: enrollmentsSummary.value.map((summary, index) => ({
        id: summary.questionnaire.id,
        title: summary.questionnaire.title,
        data: {
          ...summary,
          title: summary.questionnaire.title,
          index: index + 1,
        },
      })),
    },
  ]);

  const survey = computed(() => {
    return accountStore.groups
      .find((group: any) => group.id === groupId)
      ?.surveys?.find((survey: any) => survey.id === surveyId);
  });

  const unLockSurvey = (
    {
      date,
      onSuccessSubmit,
      onErrorSubmit,
    }: { date?: Date; onSuccessSubmit: () => void; onErrorSubmit: () => void },
    selectedLesson: any,
  ) => {
    const { onSuccess, onError } = unlockQuestionnaire({
      survey: surveyId,
      group: groupId,
      questionnaire: selectedLesson.questionnaire.id,
      unlock_at: date,
    });
    onSuccess(() => {
      loadSurveyData();
      onSuccessSubmit();
    });
    onError(() => {
      onErrorSubmit();
    });
  };

  return {
    survey,
    isSurvey,
    tabs,
    enrollmentsSummary,
    surveyListItems,
    loadSurveyData,
    unLockSurvey,
  };
}
