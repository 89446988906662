<template>
  <div class="flex flex-col gap-4 w-full">
    <slot name="header" />
    <div
      v-if="searchable || $slots['header-actions']"
      class="flex justify-between items-center gap-2">
      <SearchInput
        v-if="searchable"
        v-model="searchQuery"
        :placeholder="searchPlaceholder"
        :style="'neutral'"
        @reset-search="searchQuery = ''"/>
      <div v-if="$slots['header-actions']" class="flex gap-2 items-center">
        <slot name="header-actions" />
      </div>
    </div>

    <div v-if="filteredItems.length > 0" class="flex flex-col gap-6 mb-4">
      <template
        v-for="(section, sectionIndex) in filteredItems"
        :key="sectionIndex">
        <div class="flex flex-col gap-3">
          <div v-if="section.title" class="flex flex-1 items-center gap-2">
            <div
              v-if="section.title"
              class="text-secondary-975 text-sm font-simplerRegular">
              {{ section.title }}
            </div>
            <slot name="section-title" :section="section" />
          </div>
          <div v-if="section.items?.length > 0" class="flex flex-col gap-2">
            <div
              v-for="(item, itemIndex) in section.items"
              :key="itemIndex"
              :class="[
                'flex items-center justify-between gap-2 py-2.5 px-4 rounded-md cursor-pointer transition-colors',
                invertStyle ? 'bg-secondary-100' : 'bg-white',
                invertStyle && !item.items?.length && 'hover:bg-secondary-200',
                !invertStyle && !item.items?.length && 'hover:bg-secondary-50',
                item.hideArrow &&
                  !item.items?.length &&
                  'pointer-events-none cursor-not-allowed',
                item.disabled && 'opacity-50',
              ]"
              @click.stop="$emit('item-click', item)">
              <div class="flex items-center flex-1">
                <slot name="item" :item="item" :invert-style="invertStyle" />
              </div>
              <div
                v-if="!item.hideArrow && !item.disabled"
                :class="[
                  'flex items-center justify-center p-2 rounded-full',
                  invertStyle
                    ? 'bg-white hover:bg-secondary-50'
                    : 'bg-secondary-100 hover:bg-secondary-200',
                ]">
                <ArrowIcon class="stroke-secondary-900" :size="12" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="flex flex-col gap-6 mb-4">
      <div class="text-secondary-975 text-sm font-simplerRegular">
        {{ $t('no_data') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SearchInput } from '@amit/foundation';
import { computed, ref } from 'vue';
import { ArrowLeft as ArrowIcon } from 'lucide-vue-next';

interface ListItem {
  title: string;
  id: string | number;
  data?: any;
  hideArrow?: boolean;
  disabled?: boolean;
  items?: ListItem[];
}

interface ListSection {
  title?: string;
  data?: any;
  items: ListItem[];
}

const props = defineProps<{
  items: ListSection[];
  searchable?: boolean;
  searchPlaceholder?: string;
  invertStyle?: boolean;
}>();

const searchQuery = ref('');

const filteredItems = computed(() => {
  if (!searchQuery.value) return props.items;

  return props.items
    .map(section => ({
      ...section,
      items: section.items.filter(item =>
        item.title.toLowerCase().includes(searchQuery.value.toLowerCase()),
      ),
    }))
    .filter(section => section.items.length > 0);
});

const emit = defineEmits<{
  'item-click': [item: ListItem];
}>();
</script>
