<template>
  <PVMenu :ref="el => (weightManagement.menu = el)" :model="items" popup>
    <template #item="{ item, props: menuProps }">
      <div
        v-ripple
        class="flex items-center gap-1"
        :class="item.class ?? []"
        v-bind="menuProps.action">
        <component :is="item.icon" :size="12" />
        {{ item.label }}
      </div>
    </template>
  </PVMenu>
  <PVDialog
    v-model:visible="isConfirmDeleteOpen"
    modal
    closable
    dismissable-mask
    class="transition-all duration-500"
    :style="{ width: '21vw', minWidth: '10rem' }"
    :pt-options="{ mergeSections: false, mergeProps: true }"
    :pt="{
      header: {
        class: ['!py-2 !px-5'],
      },
      content: {
        class: ['!p-0'],
      },
    }">
    <template #header>
      <p class="font-simplerRegular text-secondary-800 text-sm">מחיקת המרכיב</p>
    </template>
    <div class="flex flex-col justify-center">
      <p class="font-simplerBold text-secondary-900 text-sm p-5">
        האם אתה בטוח שברצונך למחוק את המרכיב
        {{ weightManagement._originalCriteria?.title ?? '' }}?
      </p>
      <div class="flex justify-end items-center py-2 px-5">
        <PVButton
          severity="neutral"
          text
          label="ביטול"
          @click="isConfirmDeleteOpen = false"/>
        <PVButton
          severity="danger"
          class="!py-1.5 !px-3"
          label="מחיקה"
          @click="handleDeleteCriteria"/>
      </div>
    </div>
  </PVDialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import PVMenu from 'primevue/menu';
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';
import { PencilLine, Trash } from 'lucide-vue-next';
import { useWeightManagement } from '/@/views/CourseProgress/components/StudentsProgress/stores/WeightManagement';

const weightManagement = useWeightManagement();

const isConfirmDeleteOpen = ref(false);

const emit = defineEmits(['edit']);

const items = computed(() => [
  {
    icon: PencilLine,
    label: 'עריכת המרכיב',
    command: () => emit('edit'),
    class: [''],
  },
  {
    icon: Trash,
    label: 'מחיקת המרכיב',
    command: () => (isConfirmDeleteOpen.value = true),
    class: [''],
  },
]);

const handleDeleteCriteria = () => {
  isConfirmDeleteOpen.value = false;
  weightManagement._updatedData = weightManagement._updatedData.filter(
    ({ id }) => id !== weightManagement._originalCriteria?.id,
  );
  weightManagement.isSelectCourseOfActionOpen = true;
};
</script>
