<template>
  <div class="flex w-40 sm:w-80 md:w-40 xl:w-80">
    <p
      v-tooltip.top="lesson.title"
      class="font-simplerRegular text-secondary-900 text-sm truncate">
      {{ index ?? lesson.lesson }}. {{ lesson.title }}
    </p>
  </div>
  <div class="flex flex-1 justify-center">
    <div v-if="!locked" class="flex gap-1 items-center py-1 px-2">
      <UnlockIcon class="stroke-secondary-800" :size="14" />
      <span class="font-simplerRegular text-secondary-800 text-sm">
        פתוח ללמידה
      </span>
    </div>
    <div
      v-else-if="futureUnlock"
      class="flex gap-1 items-center bg-orange-mango-100 hover:bg-orange-mango-200 rounded-full px-2 py-1"
      @click.stop="showModal">
      <ClockIcon class="stroke-secondary-800" :size="14" />
      <span class="font-simplerRegular text-secondary-800 text-sm h-4">
        יפתח ב {{ formattedDate }}
      </span>
    </div>
    <div
      v-else
      class="flex gap-1 items-center rounded-full px-2 py-1"
      :class="
        !invertStyle
          ? 'bg-secondary-100 hover:bg-secondary-200'
          : 'bg-secondary-200 hover:bg-secondary-300'
      "
      @click.stop="showModal">
      <LockIcon class="stroke-secondary-800" :size="14" />
      <span class="font-simplerRegular text-secondary-800 text-sm h-4">
        פתיחה ללמידה
      </span>
    </div>
  </div>
  <div class="flex flex-1 justify-center">
    <div v-if="teamsEnabled" class="flex gap-1 items-center">
      <UsersIcon class="stroke-secondary-800" :size="14" />
      <span class="text-secondary-800 text-xs">למידה בקבוצות</span>
    </div>
  </div>
  <button
    v-if="files.length"
    v-tooltip.top="$t('course_settings.lesson_files')"
    class="flex items-center justify-center p-2 bg-secondary-100 rounded-full hover:bg-secondary-200"
    @click.stop="$emit('open-files', lesson)">
    <UploadCloud class="stroke-secondary-900" :size="12" />
  </button>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import {
  Users as UsersIcon,
  Unlock as UnlockIcon,
  Clock as ClockIcon,
  Lock as LockIcon,
  UploadCloud,
} from 'lucide-vue-next';
import { isToday } from 'date-fns';

const props = defineProps({
  files: {
    type: Array,
    default: () => [],
  },
  index: {
    type: Number,
    required: true,
  },
  lesson: {
    type: Object as PropType<{
      id: string;
      title: string;
      isProject?: boolean;
    }>,
    required: true,
  },
  teamsEnabled: {
    type: Boolean,
    default: false,
  },
  locked: {
    type: Boolean,
    default: true,
  },
  unlockDate: {
    type: [Date, String],
    default: null,
  },
  invertStyle: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['show-modal', 'open-files']);
const formattedDate = computed(() => {
  if (!futureUnlock.value) return '';
  const date = new Date(props.unlockDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${day}.${month}`;
});
const futureUnlock = computed(() => {
  const date = new Date(props.unlockDate);
  if (!props.unlockDate || isToday(date)) return false;
  return true;
});
const showModal = () => emit('show-modal', props.lesson);
</script>
