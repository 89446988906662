<template>
  <BaseModal
    v-show="isSetGroupModalOpened"
    @close="isSetGroupModalOpened = false">
    <template #body>
      <div class="flex flex-col gap-4 text-center">
        <span class="text-secondary-900 text-lg text-center mx-auto">
          {{
            $t('survey_page.set_student_group', {
              name: studentToUpdate.studentName,
            })
          }}
        </span>
        <div class="flex justify-end items-center">
          <PVButton
            severity="neutral"
            text
            label="ביטול"
            @click="isSetGroupModalOpened = false"/>
          <PVButton label="אישור" @click="handleSetEnrollmentGroup" />
        </div>
      </div>
    </template>
  </BaseModal>

  <div ref="tableWrapper">
    <BaseTable
      v-if="mappedStudents.length"
      :style="{ height: tableHeight }"
      :headers="tableHeaders"
      :items="mappedStudents"
      :paginator="false"
      :show-index="false"
      include-search
      show-gridlines
      label="תלמידים"
      sort-field="studentName"
      :sort-order="1"
      :pt-options="{ mergeSections: false, mergeProps: true }"
      :pt="{
        column: () => ({
          sort: () => ({
            class: 'p-3.5',
          }),
        }),
      }"
      @row-click="onRowSelect">
      <template #columnheader="{ header }">
        <div
          class="flex flex-col items-start p-3 pb-1.5 w-full h-full cursor-pointer">
          <span class="font-simplerLight text-sm truncate">
            {{ header.title }}
          </span>
        </div>
      </template>

      <template #columnbody="{ header, item }">
        <div
          class="flex flex-1 items-center h-full"
          :class="{ 'opacity-50': !item.hasEnrollment }">
          <div
            v-if="header.key === 'studentName'"
            class="flex items-center gap-1 p-2 w-full">
            <img
              class="rounded-full w-4 h-4"
              :src="item.avatar || defaultAvatarImage"
              :alt="item.fullName"/>
            <span v-tooltip.top="item.studentName" class="truncate">
              {{ item.studentName }}
            </span>
          </div>
          <div
            v-else-if="header.key === 'progress' && item.hasEnrollment"
            class="pr-2">
            <ProgressInfo
              :progress="Math.round(item.enrollment?.total_progress ?? 0)"
              :items-progress="getSurveyProgress(item.enrollment)"
              completed-text="שאלונים הושלמו"
              :border="false"/>
          </div>
          <ScoreInfo
            v-else-if="item.hasEnrollment"
            :id="`${header.questionnaireId ?? 'survey-score'}-${item.id}`"
            :allow-check-click="true"
            :allow-edit-score="false"
            :score="
              isFinished(item.id, header.questionnaireId) ? 100 : undefined
            "
            :is-score-boolean="true"
            @on-check-click="
              () =>
                router.push({
                  name: 'survey-progress-visual',
                  params: {
                    group: props.groupId,
                    survey: props.surveyId,
                  },
                  query: {
                    student: item.id,
                    questionnaire: header.questionnaireId,
                  },
                })
            "/>
        </div>
      </template>
    </BaseTable>
    <div v-else class="flex text-sm pt-5 text-secondary-700 text-light">
      אין תלמידים
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import { find, keyBy } from 'lodash';
import { BaseModal } from '@nwire/amit-design-system';
import BaseTable from '/@/views/playground/partials/BaseTable.vue';
import type { IStudent } from '/@/app/models/interfaces';
import { useRouter } from 'vue-router';
import { useStudentsStore } from '/@/app/store/students';
import { setEnrollmentGroup } from '/@/app/services/surveys';
import { sortStudents } from '/@/utils';
import PVButton from 'primevue/button';
import { useStorage } from '@vueuse/core';
import { useSurvey } from '../../composables/useSurvey';
import ScoreInfo from '/@/views/CourseProgress/components/StudentsProgress/components/ScoreInfo.vue';
import { defaultAvatarImage } from '@/utils/helpers';
import ProgressInfo from '@amit/components/src/ProgressInfo/ProgressInfo.vue';
import { tableHelper } from './composables/tableHelper';

const props = defineProps({
  groupId: {
    type: String,
    required: true,
  },
  surveyId: {
    type: String,
    required: true,
  },
  students: {
    type: Array as PropType<IStudent[]>,
    default: () => [],
  },
});

const studentsStore = useStudentsStore();
const localStateFeaturesConfig = useStorage('features_config', false);

const { enrollmentsSummary } = useSurvey(props.groupId, props.surveyId);

const studentToUpdate = ref({});
const isSetGroupModalOpened = ref(false);
const router = useRouter();

const sortedStudents = computed(() => sortStudents(props.students));

const enrollmentSummaryPerStudent = computed(() =>
  keyBy(
    studentsStore.surveyEnrollments?.map(({ user: { id }, summary }) => ({
      id,
      summary: keyBy(
        summary.map(({ questionnaire, ...other }) => ({
          ...other,
          questionnaire,
          id: questionnaire.id,
        })),
        'id',
      ),
    })),
    'id',
  ),
);

const getStudentQuestionareStatus = (studentId, questionnaireId) =>
  enrollmentSummaryPerStudent.value[studentId].summary[questionnaireId].status;

const isFinished = (studentId, questionnaireId) =>
  getStudentQuestionareStatus(studentId, questionnaireId) === 'finished';

const tableHeaders = computed(() => [
  {
    title: 'תלמידים',
    align: 'start',
    key: 'studentName',
    sortable: true,
    frozen: true,
    style: 'min-width: 8em; max-width: 8em; height: 45px;',
  },
  {
    title: 'התקדמות',
    key: 'progress',
    style: 'min-width: 10.5em; max-width: 10.5em',
  },
  ...enrollmentsSummary.value.map((summary, index) => ({
    title: `${summary.questionnaire.title}`,
    align: 'start',
    key: `questionnaire-${index}`,
    style: 'min-width: 8rem; max-width: 8rem;',
    questionnaireId: summary.questionnaire.id,
    summary,
  })),
]);

const mappedStudents = computed(() => {
  return sortedStudents.value.map(student => {
    const enrollment = find(
      studentsStore.surveyEnrollments,
      (enrollment: any) => enrollment.user.id === student.id,
    );
    return {
      id: student.id,
      studentName: student.fullName,
      avatar: enrollment?.user?.avatar,
      enrollment,
      hasEnrollment: !!enrollment,
      ...enrollmentsSummary.value.reduce((acc, summary, index) => {
        acc[`questionnaire-${index}`] = summary.questionnaire.id; // Add dynamic lesson keys
        return acc;
      }, {}),
    };
  });
});

const onRowSelect = event => {
  const studentId = event.data.id;
  const student = mappedStudents.value.find(
    student => student.id === studentId,
  );
  if (!student.hasEnrollment) {
    return openSetEnrollmentGroupModal(student);
  }
};

const openSetEnrollmentGroupModal = student => {
  if (!localStateFeaturesConfig.value) {
    return;
  }
  studentToUpdate.value = student;
  isSetGroupModalOpened.value = true;
};

const handleSetEnrollmentGroup = () => {
  const { onSuccess } = setEnrollmentGroup({
    survey: props.surveyId,
    group: props.groupId,
    user: studentToUpdate.value.id,
  });
  onSuccess(() => {
    isSetGroupModalOpened.value = false;
  });
};

const getSurveyProgress = enrollment => {
  return !enrollment
    ? []
    : enrollment.summary.map(item =>
        isFinished(enrollment.user.id, item.questionnaire.id) ? 100 : 0,
      );
};

const { tableHeight, tableWrapper } = tableHelper(mappedStudents, 45, 140);
</script>
