<template>
  <template v-if="activeTab === 'questionnaires'">
    <ListView
      :items="surveyListItems"
      searchable
      search-placeholder="חיפוש שאלון"
      @item-click="item => navigateToPreview(item.id as string)">
      <template #header-actions>
        <PVButton
          class="flex-row-reverse h-[31px]"
          label="תשובות תלמידים >"
          @click="navigateToVisualDashboard"/>
      </template>
      <template #item="{ item }">
        <LessonInfo
          :index="item.data.index"
          :lesson="item.data"
          :locked="item.data.locked"
          :unlock-date="item.data.unlock_at"
          @show-modal="$emit('open-modal', item.data)"/>
      </template>
    </ListView>
  </template>

  <template v-else-if="activeTab === 'progress'">
    <StudentsSurveyTable
      :students="students"
      :group-id="groupId"
      :survey-id="surveyId"/>
  </template>
</template>

<script setup lang="ts">
import { useSurvey } from '/@/views/CourseProgress/composables/useSurvey';
import StudentsSurveyTable from '/@/views/CourseProgress/components/StudentsProgress/StudentsSurveyTable.vue';
import PVButton from 'primevue/button';
import ListView from '@amit/foundation/List/ListView.vue';
import LessonInfo from '/@/views/CourseProgress/components/Progress/components/LessonInfo.vue';
import { useRouter } from 'vue-router';
const props = defineProps<{
  groupId: string;
  surveyId: string;
  activeTab: string;
  students: any[];
}>();

const emit = defineEmits(['open-modal']);

const router = useRouter();
const { surveyListItems } = useSurvey(props.groupId, props.surveyId);

const navigateToPreview = (id: string) => {
  const route = router.resolve({
    name: 'survey-preview',
    params: { survey: props.surveyId, questionnaire: id },
  });
  window.open(route.href, '_blank');
};

const navigateToVisualDashboard = () => {
  const route = router.resolve({
    name: 'survey-progress-visual',
    params: { group: props.groupId, survey: props.surveyId },
  });
  window.open(route.href, '_blank');
};
</script>
