<template>
  <PVDialog
    v-model:visible="isDialogOpen"
    modal
    closable
    :draggable="false"
    position="center"
    :pt-options="{ mergeSections: false, mergeProps: true }"
    :pt="{
      root: {
        class: '!w-[460px]',
      },
      header: {
        class: 'hidden',
      },
      content: {
        class: '!pt-[32px]',
      },
      footer: {
        class: '!py-3 !border-t border-secondary-300',
      },
    }"
    @hide="close">
    <div class="flex gap-2">
      <div v-if="!props.item?.scheduled" class="flex flex-col gap-2 text-right">
        <p class="text-secondary-900 text-base font-simplerBold">
          תזמון פתיחת {{ item?.type === 'course' ? 'שיעור' : 'שאלון' }}
        </p>
        <span class="text-secondary-800 text-sm w-[260px]">
          למתי לתזמן פתיחה ל{{ item?.type === 'course' ? 'שיעור' : 'שאלון' }}
          <br />
          {{ item?.title }} ב{{ parentName }}?
        </span>
        <div v-if="!props.item?.scheduled" class="w-50 mt-2">
          <PVDatePicker
            v-model="selectedDate"
            dir="ltr"
            show-icon
            fluid
            :show-on-focus="false"
            :min-date="new Date()"/>
        </div>
      </div>
      <div v-else class="flex flex-col gap-2 text-right">
        <p class="text-secondary-900 text-base font-simplerBold">
          פתיחה ללמידה
        </p>
        <span class="text-secondary-800 text-sm w-[260px]">
          האם לפתוח את {{ item?.title }} כעת?
        </span>
      </div>
      <Clock class="-ml-5 -mt-4 h-32" />
    </div>
    <template #footer>
      <div class="flex justify-end gap-2">
        <PVButton
          class="!h-[34px]"
          severity="neutral"
          text
          label="ביטול"
          @click="close"/>
        <PVButton
          class="!h-[34px] !w-[130px]"
          :label="props.item?.scheduled ? 'פתיחה' : 'אישור תזמון'"
          :disabled="!props.item?.scheduled && !selectedDate"
          :loading="loading"
          @click="submit"/>
      </div>
    </template>
  </PVDialog>
  <MessageDialog
    v-model="showSuccessDialog"
    :message="
      !props.item?.scheduled
        ? `תזמון ה${props.item?.type === 'course' ? 'שיעור' : 'שאלון'} בוצע בהצלחה`
        : `הפתיחה בוצעה בהצלחה`
    "
    :detail="
      !props.item?.scheduled
        ? `ה${props.item?.type === 'course' ? 'שיעור' : 'שאלון'} יפתח בתאריך ${selectedDate.toLocaleDateString('he-IL')}`
        : ''
    "
    :icon="Calendar"/>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import PVButton from 'primevue/button';
import PVDialog from 'primevue/dialog';
import Clock from '/@/assets/clock.svg?component';
import PVDatePicker from 'primevue/datepicker';
import Calendar from '/@/assets/calendar.svg?component';
import MessageDialog from '/@/components/MessageDialog.vue';
type ItemType = 'course' | 'survey';

interface Item {
  title: string;
  type: ItemType;
  scheduled?: boolean;
  initialDate?: Date;
}

const props = defineProps<{
  item?: Item;
  parentName?: string;
}>();

const emit = defineEmits<{
  (
    e: 'submit',
    data: {
      date?: Date;
      onSuccessSubmit: () => void;
      onErrorSubmit: () => void;
    },
  ): void;
}>();

const isDialogOpen = defineModel<boolean>();

const selectedDate = ref(props.item?.initialDate || new Date());
const loading = ref(false);
const showSuccessDialog = ref(false);

watch(
  () => props.item?.initialDate,
  newDate => {
    if (newDate) {
      selectedDate.value = new Date(newDate);
    }
  },
  { immediate: true },
);

const scheduleDate = computed(() =>
  props.item?.scheduled
    ? undefined
    : (() => {
        const date = new Date(selectedDate.value);
        // Set the time to 00:00:00 in Israel time
        date.setHours(0, 0, 0, 0);
        // Convert to UTC while preserving the local date
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      })(),
);

const submit = async () => {
  loading.value = true;

  emit('submit', {
    date: scheduleDate.value,
    onSuccessSubmit: () => {
      showSuccessDialog.value = true;
      close();
      loading.value = false;
    },
    onErrorSubmit: () => {
      loading.value = false;
    },
  });
};

const close = () => {
  isDialogOpen.value = false;
};
</script>
