<template>
  <div class="bg-blue-sea-75 rounded-md relative p-4 flex justify-between mb-5">
    <div class="flex items-start justify-between w-full">
      <div class="flex flex-col">
        <div class="flex items-center gap-1 h-8">
          <span class="font-simplerBold text-[24px] text-secondary-900">
            {{ currentCourse?.caption }}
          </span>
          <span class="font-simplerRegular text-base text-secondary-800">
            /
          </span>
          <span class="font-simplerRegular text-sm text-secondary-800 h-3.5">
            {{ currentGroup?.name }}
          </span>
          <ProgressInfo v-if="showProgress" :progress="studentsProgress" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStudentsStore } from '/@/app/store/students';
import { ProgressInfo } from '@amit/components';

defineProps<{
  currentCourse: any;
  currentGroup: any;
  showProgress: boolean;
}>();

const studentsStore = useStudentsStore();

const studentsProgress = computed(() => {
  const enrollments = studentsStore.enrollments;

  if (!enrollments?.length) {
    return 0;
  }
  const totalStudentsProgress = enrollments.reduce((sum, enrollment) => {
    return sum + (enrollment.total_progress ?? 0);
  }, 0);
  return totalStudentsProgress / enrollments.length;
});
</script>
