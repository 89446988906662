<template>
  <BaseWidget>
    <template #title>
      <div>שיתופי תלמידים בשאלון מיפוי האחרון</div>
      <div class="text-sm">{{ group.name }}</div>
    </template>
    <template #default>
      <div
        v-if="questions.length"
        class="max-h-112 min-h-25 flex flex-1 flex-col overflow-auto">
        <div
          v-for="question in questions"
          :key="question.student.id"
          class="py-5 first:pt-0 border-b border-secondary-250 last:border-0">
          <div class="font-simplerBold text-md">
            השיתוף של {{ question.student.fullName }}
          </div>
          <div class="text-secondary-600 text-sm pt-1">
            {{ formatDate(new Date(question.question.submitted_at), true) }}
          </div>
          <p class="py-2">
            {{ question.question.answer.map(a => a?.text || a).join(', ') }}
          </p>
          <p class="text-xs font-simplerLight">
            {{ question.question.questionnaire.title }}
          </p>
        </div>
      </div>
      <div
        v-else
        class="min-h-25 flex flex-1 justify-center items-center text-secondary-600 text-sm">
        אין מידע להציג כרגע
      </div>
    </template>
  </BaseWidget>
</template>

<script setup lang="ts">
import { BaseWidget } from '@nwire/amit-design-system';
import { onMounted, Ref, ref } from 'vue';
import { getSurveyEnrollments } from '/@/app/services/surveys';
import { collect } from 'collect.js';
import { formatDate } from '@/utils/helpers';

const props = defineProps({
  group: {
    type: Object,
    required: true,
  },
});

type EnrollmentQuestion = {
  question: any;
  student: any;
};

const questions: Ref<EnrollmentQuestion[]> = ref([]);

onMounted(() => {
  let { asPromised: enrollmentRequest } = getSurveyEnrollments({
    survey: props.group.surveys[0].id,
    group: props.group.id,
  });
  enrollmentRequest().then((data): any => {
    questions.value = collect(data.data)
      .map((enrollement: any) => {
        const finishedQuestionnaires = enrollement.summary.filter(
          element => element.status === 'finished',
        );
        if (!finishedQuestionnaires.length) return;

        const lastQuestion = finishedQuestionnaires
          .flatMap(ques =>
            ques.records.map(record => ({
              ...record,
              questionnaire: ques.questionnaire,
              submitted_at: ques.submitted_at,
            })),
          )
          .findLast(record => !record.question.tags.length);
        return {
          question: lastQuestion,
          student: enrollement.user,
        };
      })
      .filter((item: any) => item)
      .sortByDesc('question.submitted_at')
      .take(10)
      .all() as EnrollmentQuestion[];
  });
});
</script>
