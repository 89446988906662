<template>
  <PVDialog
    v-model:visible="showModal"
    modal
    closable
    dismissable-mask
    class="transition-all duration-500"
    :pt-options="{ mergeSections: false, mergeProps: true }"
    :pt="{
      content: {
        class: ['overflow-x-auto !p-0'],
      },
      footer: {
        class: [
          'border-t-1 border-t-secondary-200 pt-2 px-5 !pb-2 !justify-between',
        ],
      },
    }">
    <template #header>
      <p class="text-secondary-800 font-semibold py-1">הפצת התירגול לקבוצה</p>
    </template>
    <div class="flex flex-col gap-5 p-5">
      <div class="font-simplerBold">
        <div>במסך הפתיחה של הפרויקט תופיע לתלמידים הנחיה.</div>
        <div>באפשרותכם לבחור אם להפיץ את הטקסט הכתוב מטה או לערוך אותו:</div>
      </div>
      <PVTextArea
        v-model="introduction"
        :rows="15"
        :max-length="500"
        :pt="feedbackFieldPT"
        :auto-resize="false"
        :dir="locale === 'en' ? 'ltr' : 'rtl'"/>
    </div>
    <template #footer>
      <div class="flex flex-1 justify-end items-center gap-2">
        <PVButton
          severity="neutral"
          text
          label="ביטול"
          class="font-semibold"
          @click="closeModal"/>
        <PVButton
          class="!py-1.5 !px-6"
          label="הפצה"
          :loading="loading"
          :disabled="loading"
          @click="distribute"/>
      </div>
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';
import PVTextArea from 'primevue/textarea';
import { feedbackFieldPT } from '/@/views/components/LessonSubmission/styles';

defineProps({
  locale: {
    type: String,
    default: 'he',
  },
});

const showModal = defineModel<boolean>('showModal');
const introduction = defineModel<string>('introduction');

const emit = defineEmits(['close', 'distribute']);
const introModel = ref('');
const loading = ref(false);

watch(
  () => introduction.value,
  introduction => {
    introModel.value = introduction?.replace(/\\n/g, '\n') || '';
  },
);

const distribute = () => {
  loading.value = true;
  emit('distribute', introModel.value);
};

const closeModal = () => {
  emit('close');
};
</script>
