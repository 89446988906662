<template>
  <div class="w-full h-[45px] flex items-center py-2 px-3 relative group/score">
    <button
      v-if="allowCheckClick && editMode?.id !== id"
      class="text-primary-600 text-xs absolute left-0 transform -translate-x-full transition-transform duration-300 ease-in-out group-hover/score:translate-x-0 bg-primary-200 hover:bg-primary-300 px-2 py-1 h-full"
      @click.stop="emit('onCheckClick')">
      בדיקה
    </button>
    <div
      v-if="!isEditing"
      v-tooltip.top="allowEditScore ? 'לחץ להזנת ציון' : ''"
      class="w-full h-full flex items-center justify-center rounded-md"
      :class="[
        allowCheckClick &&
          'transition-all duration-300 group-hover/score:ml-[42px]',
        alert ? style.border : style.background,
        allowEditScore && 'cursor-pointer',
      ]"
      :disabled="!allowEditScore"
      @click="handleEditClick">
      <div
        v-if="!isLoading"
        class="flex gap-1 items-center"
        :class="style.color">
        <template v-if="isScoreBoolean">
          <span class="h-2.6">
            <Check v-if="score" :size="15" />
            <template v-else>-</template>
          </span>
        </template>
        <template v-else-if="score !== null">
          <span class="h-2.6">{{ score }}</span>
          <AlertCircleIcon v-if="alert" :size="14" />
        </template>
        <div v-else-if="progress !== null" class="relative">
          <ProgressBar
            class="w-[6rem] transition-opacity duration-300 group-hover/score:opacity-0"
            :value="progress"
            :show-value="false"/>
          <span
            class="text-xs text-secondary-800 transition-opacity opacity-0 group-hover/score:opacity-100 duration-300 absolute -top-1">
            {{ progress }}% הושלם
          </span>
        </div>
        <div v-else>
          <span class="h-2.6">-</span>
        </div>
      </div>
      <PVProgressSpinner v-else class="!w-4 !h-4" stroke-width="4" />
    </div>

    <div v-else class="w-full h-full flex items-center justify-center">
      <PVInputNumber
        ref="inputRef"
        v-model="updatedScore"
        class="h-[30px]"
        :default-value="''"
        :min="0"
        :max="100"
        show-buttons
        @blur="handleBlur"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, nextTick } from 'vue';
import PVInputNumber from 'primevue/inputnumber';
import PVProgressSpinner from 'primevue/progressspinner';
import { Check } from 'lucide-vue-next';
import { AlertCircleIcon } from 'lucide-vue-next';
import ProgressBar from 'primevue/progressbar';

const emit = defineEmits(['onCheckClick', 'updateScore']);

const props = defineProps({
  allowCheckClick: {
    type: Boolean,
    default: false,
  },
  allowEditScore: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    required: true,
  },
  score: {
    type: Number,
    default: null,
  },
  isScoreBoolean: {
    type: Boolean,
    default: false,
  },
  alert: {
    type: Boolean,
    default: false,
  },
  progress: {
    type: Number,
    default: null,
  },
});

const score = computed<number | null>(() =>
  props.score != null ? Math.round(props.score) : null,
);
const editMode = ref<{ id: string } | null>(null);
const updatedScore = ref<number | null>(score.value);
const inputRef = ref<any>(null);
const isLoading = ref(false);

const levels = [
  {
    range: [0, 50],
    style: {
      color: 'text-red-500',
      background: 'bg-red-50',
      border: 'border border-red-300',
    },
  },
  {
    range: [51, 79],
    style: {
      color: 'text-orange-500',
      background: 'bg-orange-50',
      border: 'border border-orange-300',
    },
  },
  {
    range: [80, 120],
    style: {
      color: 'text-green-700',
      background: 'bg-green-50',
      border: 'border border-aqua-marine-300',
    },
  },
];

const isInRange = (value, [min, max]: number[]) => value >= min && value <= max;

const style = computed(() => {
  const level =
    score.value !== null
      ? levels.find(({ range }) => isInRange(score.value, range))
      : null;
  return (
    level?.style ?? {
      color: 'text-secondary-600',
      background: 'bg-transparent',
    }
  );
});

const isEditing = computed(() => {
  return editMode.value?.id === props.id;
});

const handleEditClick = () => {
  if (props.allowEditScore) {
    editMode.value = {
      id: props.id,
    };
    updatedScore.value = score.value;
  }
};

watch(editMode, async newValue => {
  if (newValue?.id === props.id) {
    await nextTick();
    setTimeout(() => {
      inputRef.value?.$el.querySelector('input')?.focus();
    }, 0);
  }
});

watch(score, newScore => {
  if (isLoading.value && newScore === updatedScore.value) {
    isLoading.value = false;
  }
});

const handleBlur = ({ value }) => {
  const wasEditing = isEditing.value;
  editMode.value = null;

  if (
    wasEditing &&
    value !== '' &&
    value !== score.value &&
    Number(value) !== props.score &&
    Number(value) >= 0 &&
    Number(value) <= 100
  ) {
    isLoading.value = true;
    emit('updateScore', { score: Number(value) });
  }
};
</script>
