import {
  onBeforeMount,
  ref,
  onMounted,
  nextTick,
  onBeforeUnmount,
  watch,
} from 'vue';

export const tableHelper = (
  items: any,
  tableRowHeight?: number,
  tableHeaderHeight?: number,
) => {
  const tableHeight = ref('0px');
  const tableWrapper = ref<HTMLElement | null>(null);

  const calculateTableHeight = () => {
    if (!tableWrapper.value) return;

    const viewportHeight = window.innerHeight;
    const tableTop = tableWrapper.value.getBoundingClientRect().top;
    const maxHeight = viewportHeight - tableTop;
    const rowHeight = tableRowHeight || 45;
    const headerHeight = tableHeaderHeight || 153;
    const calculatedHeight = items.value.length * rowHeight + headerHeight;
    tableHeight.value = `${Math.min(maxHeight, calculatedHeight)}px`;
  };

  onBeforeMount(() => {
    window.addEventListener('resize', calculateTableHeight);
  });

  onMounted(() => {
    nextTick(() => {
      calculateTableHeight();
    });
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', calculateTableHeight);
  });

  watch(items, () => {
    nextTick(calculateTableHeight);
  });

  return {
    tableHeight,
    tableWrapper,
    calculateTableHeight,
  };
};
