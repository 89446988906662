<template>
  <template v-if="activeTab === 'lessons'">
    <FilesUploadSidebar
      v-model="filesSidebarVisible"
      :outlines="courseData?.outline || []"
      :units="courseData?.units || []"
      :lesson-id="selectedLessonId"
      :readonly="true"/>
    <ListView
      :items="unitsLessons"
      searchable
      search-placeholder="חיפוש שיעור"
      @item-click="item => navigateToPreview(item.data.lesson.id)">
      <template #header-actions>
        <PVButton
          class="h-[31px] w-33"
          :label="
            $t('course_page.files_library') + ' (' + visibleFilesCount + ')'
          "
          plain
          severity="neutral"
          @click="filesSidebarVisible = true">
          <template #icon>
            <img :src="BookOpenIcon" alt="book_icon" class="w-3 h-3" />
          </template>
        </PVButton>
        <PVButton
          class="self-end w-33 h-[31px]"
          :label="$t('course_page.course_preview')"
          @click="navigateToPreview(courseModule?.summary[0]?.lesson.id)">
          <template #icon>
            <Playicon :size="12" />
          </template>
        </PVButton>
      </template>
      <template #item="{ item }">
        <div v-if="item.items" class="pt-1 flex flex-1">
          <ListView
            :items="[item]"
            invert-style
            @item-click="item => navigateToPreviewProject(item.id as string)">
            <template #item="{ item, invertStyle }">
              <LessonInfo
                :index="item.data.lessonIndex"
                :lesson="item.data.lesson"
                :locked="item.data.locked"
                :invert-style="invertStyle"
                @show-modal="$emit('openModal', item.data.lesson)"/>
            </template>
          </ListView>
        </div>
        <LessonInfo
          v-else
          :index="item.data.lessonIndex"
          :lesson="item.data.lesson"
          :teams-enabled="item.data.teams_enabled"
          :locked="item.data.locked"
          :unlock-date="item.data.unlock_at"
          :files="
            courseFiles.filter(f => f.metadata.lesson === item.data.lesson?.id)
          "
          @open-files="openFiles"
          @show-modal="$emit('openModal', item.data.lesson)"/>
      </template>
    </ListView>
  </template>
  <template v-else-if="activeTab === 'grades'">
    <StudentsCourseTable :course-module="courseModule" :students="students" />
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import StudentsCourseTable from '../StudentsProgress/StudentsCourseTable.vue';
import FilesUploadSidebar from '/@/views/Lobby/CourseSettings/FilesUploadSidebar.vue';
import LessonInfo from './components/LessonInfo.vue';
import ListView from '@amit/foundation/List/ListView.vue';
import { Play as Playicon } from 'lucide-vue-next';
import BookOpenIcon from '/@/assets/BookOpen.svg?url';
import PVButton from 'primevue/button';
import { useCourse } from '/@/views/CourseProgress/composables/useCourse';

const filesSidebarVisible = ref(false);

const props = defineProps<{
  groupId: string;
  courseId: string;
  students: any;
  activeTab: string;
}>();

const emit = defineEmits(['openModal']);

const {
  courseModule,
  unitsLessons,
  courseData,
  courseFiles,
  navigateToPreview,
  navigateToPreviewProject,
} = useCourse(props.groupId, props.courseId);

const selectedLessonId = ref('');

const openFiles = lesson => {
  selectedLessonId.value = lesson.id;
  filesSidebarVisible.value = true;
};

const visibleFilesCount = computed(() => {
  const lessons = unitsLessons.value.flatMap(u => u.items).map(l => l.id);
  console.log(courseFiles.value);
  const allFiles = [
    ...courseFiles.value.filter(
      file =>
        lessons.find(l => file.metadata.lesson === l) || !file.metadata.lesson,
    ),
  ];
  return allFiles.length;
});
</script>
