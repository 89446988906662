<template>
  <BaseWidget>
    <template #title>
      <div>תירגול מעשי</div>
    </template>
    <template #default>
      <PVButton
        class="!py-1.5 !px-3"
        label="הפצת תירגול מעשי לקבוצה"
        @click="showModal = true"/>
    </template>
  </BaseWidget>
  <ProjectDistributeModal
    v-model:show-modal="showModal"
    v-model:introduction="introduction"
    :locale="locale"
    @close="closeModal"
    @distribute="handleDistribute"/>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { BaseWidget } from '@nwire/amit-design-system';
import ProjectDistributeModal from '/@/views/CourseProgress/components/Progress/components/ProjectDistributeModal.vue';
import { fetchProject } from '/@/app/services/projects';
import PVButton from 'primevue/button';

const props = defineProps({
  project: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['distribute']);

const showModal = ref(false);
const introduction = ref('');
const locale = ref('');

const closeModal = () => {
  showModal.value = false;
};

const handleDistribute = (introductionText: string) => {
  emit('distribute', {
    introductionText,
    onSuccess: () => closeModal(),
    onError: () => closeModal(),
  });
};

const setProject = () => {
  if (!props.project) {
    return;
  }
  const { onSuccess } = fetchProject(props.project);
  onSuccess(({ data }) => {
    introduction.value = data?.introduction ?? '';
    locale.value = data?.locale ?? 'he';
  });
};

watch(showModal, isVisible => {
  if (isVisible) {
    setProject();
  }
});
</script>
