<template>
  <div
    class="flex gap-1.5 items-center py-1 px-3 rounded-full bg-white"
    :class="{
      'cursor-pointer hover:bg-secondary-200': itemsProgress.length,
      'border-1 border-secondary-250': border,
    }"
    @click="toggle">
    <div class="flex gap-2 items-center">
      <div
        v-if="styles.color"
        class="min-w-1.5 w-1.5 min-h-1.5 h-1.5 rounded-full"
        :style="{ background: styles.color }"></div>
      <div class="font-simplerRegular text-secondary-900 text-xs h-3.5">
        {{ styles.label }}
      </div>
    </div>
    <ProgressBar
      v-if="progress > 0 && progress < 100"
      class="w-[3rem]"
      :value="progress"
      :show-value="false"/>
  </div>
  <OverlayPanel ref="op">
    <div class="p-3 w-[204px] flex flex-col">
      <label class="text-xs font-semibold text-secondary-900 mb-1.5">
        {{ `${progress}% הושלם` }}
      </label>
      <Divider />
      <label class="my-3 text-xs text-secondary-900">
        {{ `${completedItems}/${itemsProgress.length} ${completedText}` }}
      </label>
      <div class="flex flex-1 gap-[1px] items-center">
        <ProgressBar
          v-for="(item, index) in itemsProgress"
          :key="index"
          :value="item"
          :show-value="false"
          :style="{ flexGrow: 1 }"/>
      </div>
    </div>
  </OverlayPanel>
</template>

<script setup lang="ts">
import ProgressBar from 'primevue/progressbar';
import OverlayPanel from 'primevue/overlaypanel';
import Divider from 'primevue/divider';
import { computed, ref } from 'vue';

const props = defineProps({
  progress: {
    type: Number,
    default: 0,
  },
  itemsProgress: {
    type: Array as () => number[],
    default: () => [],
  },
  label: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    default: '',
  },
  border: {
    type: Boolean,
    default: true,
  },
  completedText: {
    type: String,
    default: 'שיעורים הושלמו',
  },
});

const settings = {
  NOT_STARTED: {
    label: 'פתוח ללמידה',
    color: '#F0920F',
  },
  IN_PROGRESS: {
    label: 'בלמידה',
    color: '#0FA9FF',
  },
  COMPLETED: {
    label: 'הושלם',
    color: '#3F9F44',
  },
};

const styles = computed(() => {
  if (props.color) {
    return {
      label: props.label,
      color: props.color,
    };
  }

  let status = 'COMPLETED';
  if (!props.progress) {
    status = 'NOT_STARTED';
  } else if (props.progress < 100) {
    status = 'IN_PROGRESS';
  }

  return settings[status];
});
const completedItems = computed(() => {
  return props.itemsProgress.filter(progress => progress === 100).length;
});

const op = ref();
const toggle = event => {
  if (!props.itemsProgress.length) return;
  op.value.toggle(event);
};
</script>
