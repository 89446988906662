<template>
  <CourseProgressHeader
    :current-course="courseModule?.course || survey"
    :current-group="currentGroup"
    :show-progress="!isSurvey"/>
  <ScheduleUnlockDialog
    v-model="isUnlockDialogOpen"
    :item="scheduleUnlockDialogItem"
    :parent-name="courseModule?.course?.caption || survey?.caption"
    @submit="
      vars =>
        isSurvey
          ? unLockSurvey(vars, selectedLesson)
          : unLock(vars, selectedLesson)
    "/>
  <BaseTabs
    v-if="currentGroup && (courseModule || isSurvey)"
    v-model="activeTab"
    variant="underline"
    directive="show">
    <BaseTab
      v-for="tab in tabs"
      :key="tab.name"
      :name="tab.name"
      :title="tab.title">
      <div class="mt-2">
        <CourseTabs
          v-if="!isSurvey"
          :group-id="groupId"
          :course-id="courseId"
          :students="currentGroup.students"
          :active-tab="activeTab"
          @open-modal="openModal"/>
        <SurveyTabs
          v-else
          :group-id="groupId"
          :survey-id="courseId"
          :active-tab="activeTab"
          :students="currentGroup.students"
          @open-modal="openModal"/>
      </div>
    </BaseTab>
  </BaseTabs>

  <template v-if="!isSurvey && route.query.view !== 'grades'">
    <SideWidget v-if="teamsEnabled">
      <TeamsWidget :course-module="courseModule" />
    </SideWidget>
    <SideWidget v-if="courseModule && !projectDistributed">
      <ProjectWidget
        v-if="projectId"
        :project="projectId"
        @distribute="distribute"/>
    </SideWidget>
  </template>
  <template v-if="isSurvey && route.query.view !== 'progress'">
    <SideWidget v-if="currentGroup?.surveys.length" base>
      <SurveyRecentOpenQuestionWidget :group="currentGroup" />
    </SideWidget>
  </template>

  <LoadingOverlay
    :active="!(currentGroup && courseModule) && !isSurvey"
    :can-cancel="false"
    :is-full-page="true"/>
</template>

<script setup lang="ts">
import {
  ref,
  watchEffect,
  computed,
  resolveComponent,
  onMounted,
  watch,
} from 'vue';
import { useAccountStore } from '/@/app/store/account';
import { useRoute, useRouter } from 'vue-router';
import { BaseTabs, BaseTab } from '@nwire/amit-design-system';
import TeamsWidget from '/@/views/partials/TeamsWidget.vue';
import ProjectWidget from '/@/views/CourseProgress/components/Progress/components/ProjectWidget.vue';
import { collect } from 'collect.js';
import { isEmpty } from 'lodash-es';
import { SideWidget } from '@amit/layout';
import SurveyRecentOpenQuestionWidget from '/@/views/CourseProgress/components/Progress/components/SurveyRecentOpenQuestionWidget.vue';
import CourseProgressHeader from '/@/views/CourseProgress/components/CourseProgressHeader.vue';
import ScheduleUnlockDialog from '/@/views/CourseProgress/components/Progress/components/ScheduleUnlockDialog.vue';
import CourseTabs from '/@/views/CourseProgress/components/Progress/CourseTabs.vue';
import { useCourse } from '/@/views/CourseProgress/composables/useCourse';
import { useSurvey } from '/@/views/CourseProgress/composables/useSurvey';
import { useProject } from '/@/views/CourseProgress/composables/useProject';
import SurveyTabs from '/@/views/CourseProgress/components/Progress/SurveyTabs.vue';
const LoadingOverlay = resolveComponent('LoadingOverlay');

const accountStore = useAccountStore();
const router = useRouter();
const route = useRoute();
const currentGroup = ref<any>(null);
const activeTab = ref('');
const isUnlockDialogOpen = ref(false);
const groupId = route.params.group as string;
const courseId = route.params.course as string;

const {
  courseModule,
  projectId,
  teamsEnabled,
  projectDistributed,
  loadCourseData,
  resetCourseModule,
  tabs: courseTabs,
  unLock,
} = useCourse(groupId, courseId);
const {
  tabs: surveyTabs,
  loadSurveyData,
  isSurvey,
  survey,
  unLockSurvey,
} = useSurvey(groupId, courseId);
const { distribute, setEnrollment, resetEnrollment } = useProject(
  { group: groupId, course: courseId },
  projectId,
);

onMounted(() => {
  const viewParam = route.query.view as string;
  const validTabs = tabs.value.map(tab => tab.name);

  if (viewParam && validTabs.includes(viewParam)) {
    activeTab.value = viewParam;
  } else {
    activeTab.value = tabs.value[0].name;
    router.replace({ query: { ...route.query, view: activeTab.value } });
  }

  isSurvey.value ? loadSurveyData() : loadCourseData();
});

watch(
  projectId,
  () => {
    setEnrollment();
  },
  { immediate: true },
);

watch(
  [courseId, groupId],
  () => {
    if (courseId && groupId) {
      resetEnrollment();
      resetCourseModule();
    }
  },
  { immediate: true },
);

watchEffect(() => {
  try {
    if (!isEmpty(accountStore.user)) {
      currentGroup.value = collect(accountStore.groups)
        .where('id', groupId)
        .first();

      accountStore.selectedGroup = currentGroup.value;
      accountStore.selectedCourse = courseModule.value?.course;
    }
  } catch (error) {
    console.error(error);
    router.push({ name: 'home' });
  }
});

const tabs = computed(() => {
  return isSurvey.value ? surveyTabs : courseTabs;
});

watch(activeTab, newVal => {
  router.replace({ query: { ...route.query, view: newVal } });
});

const selectedLesson = ref();

const summary = computed(() => courseModule.value?.summary ?? []);

const openModal = async lesson => {
  selectedLesson.value = lesson;
  isUnlockDialogOpen.value = true;
};

const selectedLessonUnlockAt = computed(() => {
  if (!selectedLesson.value) return null;
  return (
    summary.value?.find(item => item.lesson.id === selectedLesson.value?.id)
      ?.unlock_at ?? selectedLesson.value?.unlock_at
  );
});

const scheduleUnlockDialogItem = computed(() => ({
  title: selectedLesson.value?.title,
  type: isSurvey.value ? 'survey' : ('course' as const),
  scheduled: selectedLesson.value?.isProject,
  initialDate: selectedLessonUnlockAt.value
    ? new Date(selectedLessonUnlockAt.value)
    : new Date(),
}));
</script>
