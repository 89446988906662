<template>
  <PVDialog
    v-model:visible="weightManagement.isSelectCourseOfActionOpen"
    modal
    closable
    dismissable-mask
    class="transition-all duration-500"
    :style="{ width: '28vw' }"
    @close="onCancel">
    <template #header>
      <p class="font-simplerRegular text-secondary-800 text-sm font-semibold">
        עדכון משקל לציון
      </p>
    </template>
    <div class="flex flex-col justify-center gap-5">
      <div class="flex flex-col gap-1">
        <p class="font-simplerBold text-secondary-900 text-sm">
          איך תרצה לשקלל את שאר מרכיבי הקורס?
        </p>
        <p class="font-simplerRegular text-secondary-900 text-sm">
          בשקלול אוטמטי שאר מרכיבי הקורס ישוקללו מחדש באופן יחסי
        </p>
      </div>
      <div class="flex justify-end items-center">
        <PVButton
          severity="neutral"
          text
          label="שקלול ידני"
          class="font-semibold"
          @click="onManualClick"/>
        <PVButton
          class="!py-1.5 !px-3"
          label="שקלול אוטומטי"
          :loading="isLoading"
          @click="onAutomaticClick"/>
      </div>
    </div>
  </PVDialog>
</template>

<script setup lang="ts">
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';
import { useWeightManagement } from '/@/views/CourseProgress/components/StudentsProgress/stores/WeightManagement';

const weightManagement = useWeightManagement();

defineProps<{
  isLoading: boolean;
}>();

const emit = defineEmits<{
  onManual: [];
  onAutomatic: [];
  onCancel: [];
}>();

const onManualClick = () => {
  weightManagement.isSelectCourseOfActionOpen = false;
  emit('onManual');
};

const onAutomaticClick = () => {
  emit('onAutomatic');
};

const onCancel = () => {
  emit('onCancel');
};
</script>
